/*
blue: rgb(73,155,201)
green: rgb(110,192,56)
orange: orange
*/

/*
Source Code Pro
Valid Weights: 100, 200, 400, 500, 600, 700, 800
*/
@font-face {
  font-family: Source Code Pro Woff;
  src: url(../fonts/SourceCodePro-ExtraLight.woff);
  font-weight: 100;
}
@font-face {
  font-family: Source Code Pro Woff;
  src: url(../fonts/SourceCodePro-Light.woff);
  font-weight: 200;
}
@font-face {
  font-family: Source Code Pro Woff;
  src: url(../fonts/SourceCodePro-Regular.woff);
  font-weight: 400; /* Normal */
}
@font-face {
  font-family: Source Code Pro Woff;
  src: url(../fonts/SourceCodePro-Medium.woff);
  font-weight: 500;
}
@font-face {
  font-family: Source Code Pro Woff;
  src: url(../fonts/SourceCodePro-Semibold.woff);
  font-weight: 600;
}
@font-face {
  font-family: Source Code Pro Woff;
  src: url(../fonts/SourceCodePro-Bold.woff);
  font-weight: 700; /* Bold */
}
@font-face {
  font-family: Source Code Pro Woff;
  src: url(../fonts/SourceCodePro-Black.woff);
  font-weight: 800;
}

html>body {
  margin: 0px;

  background: #1d1f21;

  font-family: Source Code Pro Woff, Monaco, monospace;
  font-weight: 400;
  /*overflow-y: hidden; /* Use this after implimenting scrollbars. */
}

body>footer {
  display: block;

  position: fixed;

  left: 0px; 
  right: 0px;
  bottom: 0px; 

  min-width: 600px; 

  border-top: 1px solid #313131;
  background: #1b1b1b;
}

#bottom_bar {
  left: 0px; 
  right: 0px;
  bottom: 0px;

  padding: 4px 20px 4px 20px;

  margin: auto;
  max-width: 960px;

  color: gray;
  font-size: 8pt;
}

.footer_left {
  display: inline-block;
}

.footer_right {
  display: inline-block;
  float: right;
}

a:hover {background: #2e5c00;} 
.inline_button, .inline_button:link {color: white;}

.button:hover {
  background: #2e5c00;
  cursor: pointer;
}

/* CSS code to make the content look HTML-y */